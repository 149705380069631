<template>
  <div>
    <v-app>
      <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Kelompok Kelas</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/masters/class-groups/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

      <div class="card mb-3">
        <div class="card-body py-3 px-8">
          <div class="row p-0 justify-content-end">
            <div class="col-md-3">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text squared>
                    <b-icon-search></b-icon-search>
                  </b-input-group-text>
                </template>
                <b-form-input
                  type="text"
                  v-model="filter.name"
                  placeholder="Cari Nama"
                  @keyup="filterByName"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-md-3">
              <b-input-group>
                <treeselect
                  v-model="filter.major_id"
                  :multiple="false"
                  placeholder="Pilih Prodi"
                  :options="majors"
                  @input="filterByMajor"
                />
              </b-input-group>
            </div>
            <div class="col-md-3">
              <b-input-group>
                <treeselect
                  v-model="filter.school_year_id"
                  :multiple="false"
                  placeholder="Pilih Tahun Ajaran"
                  :options="school_years"
                  @input="filterBySchoolYear"
                />
              </b-input-group>
            </div>
            <!-- <div class="col-md-2 offset-md-1 text-right">
              <b-button
                squared
                variant="primary"
                @click="$router.push('/masters/class-groups/add')"
                >Tambah</b-button
              >
            </div> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-4"
          v-for="classgroup in class_groups"
          :key="classgroup.id"
        >
          <v-hover v-slot="{ hover }">
            <!-- <router-link
              :to="`/masters/class-groups/detail/${classgroup.id}/${classgroup.user_id}`"
            > -->
              <b-card :style="hover ? 'opacity: 0.8' : ''" no-body>
                <b-card-body style="cursor: pointer" class="px-3 pb-0" @click="$router.push(`/masters/class-groups/sub-detail/${classgroup.id}/${classgroup.user_id}`)">
                  <div class="d-flex justify-content-between">
                    <div>
                      <b-card-title class="font-size-h5">{{
                        classgroup.name
                      }}</b-card-title>
                    </div>
                    <!-- <div>
                      <b-dropdown
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template #button-content>
                          <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                        </template>
                        <b-dropdown-item
                          @click="
                            $router.push(
                              `/masters/class-groups/edit/${classgroup.id}`
                            )
                          "
                          >Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click="classGroupDelete(classgroup.id)"
                          >Hapus</b-dropdown-item
                        >
                      </b-dropdown>
                    </div> -->
                  </div>
                  <table class="table table-borderless">
                    <tr>
                      <td>Tingkat</td>
                      <td>:</td>
                      <td>{{classgroup.grade_name}}</td>
                    </tr>
                    <tr>
                      <td>Prodi</td>
                      <td>:</td>
                      <td>{{classgroup.major_name}}</td>
                    </tr>
                    <tr>
                      <td>Semester</td>
                      <td>:</td>
                      <td>{{classgroup.semester_name}}</td>
                    </tr>
                    <!-- <tr>
                      <td>Jenis Periode</td>
                      <td>:</td>
                      <td>{{classgroup.periode_type_name}}</td>
                    </tr> -->
                    <tr>
                      <td>Jumlah Mahasiswa</td>
                      <td>:</td>
                      <td>{{classgroup.total_students}}</td>
                    </tr>
                    <tr>
                      <td>Dosen Wali</td>
                      <td>:</td>
                      <td>{{classgroup.user_name}}</td>
                    </tr>
                  </table>
                </b-card-body>
                <div class="d-flex justify-content-end mb-3 pr-3" v-if="btnAccess">
                    <div class="mr-2">
                      <i
                        style="cursor: pointer; position:absolute; right:33px;"
                        class="fas fa-pencil-alt btn-action text-info"
                        @click="
                          $router.push(
                            `/masters/class-groups/edit/${classgroup.id}`
                          )
                        "
                      ></i>
                    </div>
                    <div class="">
                      <i
                        style="cursor: pointer"
                        class="fas fa-trash-alt btn-action text-danger"
                        @click="classGroupDelete(classgroup.id)"
                      ></i>
                    </div>
                  </div>
              </b-card>
            <!-- </router-link> -->
          </v-hover>
        </div>
      </div>
    </v-app>

    <!-- <TablePresence /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import TablePresence from "@/view/components/presence/Table.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components:{
    TablePresence
  },
  data() {
    return {
      filter: {
        name: "",
        major_id: "",
        school_year_id: "",
      },
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      class_groups: [],
      teachers: [],
      card_text_place: "min-height: 230px",
      majors: [],
      school_years: [],
      // access
      btnAccess: false
    };
  },
  methods: {
    async pagination() {
      let currentPage = "";
      let perPage = "";
      let filterParams = `&name=${this.filter.name}&major_id=${this.filter.major_id}&school_year_id=${this.filter.school_year_id}`;
      let response = await module.paginate(
        `api/class-groups`,
        `?page=${currentPage}&page_size=${perPage}${filterParams}`
      );

      this.class_groups = response.data;
    },
    async getMajorOption() {
      let response = await module.setTreeSelect("api/majors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.majors = response.data;
        this.majors.unshift({
          label: "Pilih Prodi",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getSchoolYearOption() {
      let response = await module.setTreeSelect("api/school-years");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.school_years = response.data;
        this.school_years.unshift({
          label: "Pilih Tahun Ajaran",
          id: "",
          isDisabled: true,
        });
      }
    },
    filterByName() {
      this.pagination();
    },
    async filterByMajor(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.major_id = await "";
        this.pagination();
      } else {
        this.filter.major_id = await evt;
        this.pagination();
        //("ok", this.filter.major_id);
      }
    },

    async filterBySchoolYear(evt) {
      //("action", evt);
      if (!evt) {
        this.filter.school_year_id = await "";
        this.pagination();
      } else {
        this.filter.school_year_id = await evt;
        this.pagination();
        //("ok", this.filter.school_year_id);
      }
    },
    async classGroupDelete(id) {
      // Delete Data
      let result = await module.delete(`api/class-groups/${id}`);
      // If Deleted
      if (result) {
        this.pagination();
        this.$root.$emit("refreshClassroom");
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8004") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kelompok Kelas" }]);
    this.getMajorOption();
    this.getSchoolYearOption();
    this.checkAccess();
  },
  created() {
    this.pagination();
  },
};
</script>

<style scoped>
.card-title {
  color: black;
}

.card-text {
  color: rgb(94, 92, 92) !important;
}

.class-highlight:hover {
  color: #1bc5bd !important;
}

.router-class:hover {
  color: #1bc5bd !important;
}

.btn-action:hover{
  font-size: 20px;
}

.image-input-wrapper {
  width: 40px !important;
  height: 40px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Vuetify Override font */
.v-application--wrap {
  min-height: 0px !important;
  font-family: Poppins, Helvetica, "sans-serif" !important;
}
</style>